import React from "react";
import TodoItem from "./TodoItem";
const TodoList =  ({todos,delTodo,toggleTodo}) =>{

    return (
       <ul>
         {todos.map((todo,index)=>
            <TodoItem 
             key = {todo.id}
             index = {index}
             toggleTodo = {toggleTodo}
             todo = {todo}
             delTodo = {delTodo}
            />
            )}
       </ul>
    )
}
export default TodoList;