import React, { useState } from "react";
 const AddTodo = ({addTodo})=>{
 const [text,setText] = useState("")
    const handleSubmit = (e)=>{
      e.preventDefault()
      if(text.trim()){
        addTodo(text)
        setText(" ")
      }
    }
    return (
        <form onSubmit={handleSubmit}>
          <input
           type="text" 
           placeholder="Enter your Daily Tasks"
           onChange={(e)=>setText(e.target.value)}
           />
          <button>Add Todo</button>
        </form>
    )
    }
    export default AddTodo