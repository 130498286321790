import React from "react";
const  TodoItem = ({todo,toggleTodo,delTodo,index})=>{
    // console.log(todo.text)
    return (
        <li>
           {`${index+1}-`}
            <input 
             type="checkbox"
             checked = {todo.completed}
             onChange={()=>toggleTodo(todo.id)}
            />
            <span style={{textDecoration: todo.completed?"line-through":"none"}}>{todo.text}</span>
            <button onClick={()=>delTodo(todo.id)}>Delete</button>
        </li>
    )
}
export default TodoItem