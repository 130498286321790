import React,{useEffect, useState} from "react";
import AddTodo from "./components/AddTodo";
import TodoList from "./components/TodoList";
import "./App.css"
export default function App(){

  const [Todos,setTodos] = useState(()=>{
   let  Data = localStorage.getItem("todos")
    let storedData = JSON.parse(Data)
    return storedData?storedData:[]
  })
  // console.log(Todos)
  const addTodo = (text)=>{
    setTodos([...Todos,{id:Date.now(),text:text,completed:false}])
  }
  const delTodo = (id)=>{
setTodos(
  Todos.filter(todo=>todo.id!==id)
)
  }
  const toggleTodo = (id)=>{
    setTodos(
      Todos.map(todo=>todo.id===id?{...todo,completed:!todo.completed}:todo)
    )
  }
  useEffect(()=>{
    localStorage.setItem("todos",JSON.stringify(Todos))
  })
  return (
    <div>
      <h1>A simple todolist App</h1>
     <AddTodo
     addTodo = {addTodo}
     />
     <TodoList
     todos={Todos}
     delTodo = {delTodo}
     toggleTodo = {toggleTodo}
     />
    </div>
  )

}